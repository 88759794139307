<template>
  <div>
    <div class="form-control-chips-time">
      <div class="time-chips">
        <span v-for="(time, index) in selectedTimes" :key="time" class="chip">
          {{ time }}
          <button
            type="button"
            class="remove-chip"
            :class="{ disabled: index === 0 && selectedTimes.length === 1 }"
            :disabled="index === 0 && selectedTimes.length === 1"
            @keydown.enter.prevent
            @click="removeTime(index)"
          >
          <img src="../../assets/img/remove-btn.svg" alt="remove" />
          </button>
        </span>

        <div class="container-add">
          <button
          type="button"
          class="add-chip"
          @click="toggleDropdown"
          :disabled="selectedTimes.length >= 16"
          :class="{ disabled: selectedTimes.length >= 16 }"
        >
          <img src="../../assets/img/add-btn.svg" alt="add" />
        </button>
        <div v-if="showDropdown" class="time-dropdown" ref="dropdownList">
          <ul>
            <li
              v-for="time in availableTimes"
              :key="time"
              :class="{ disabled: isTimeSelected(time) }"
              @click="!isTimeSelected(time) && selectTime(time)"
            >
              {{ time }}
            </li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TIME_INTERVALS_30_MINUTES } from "../../utils/constants/timeInterval";

export default {
  props: {
    selectedTimes: {
      type: Array,
      default: () => ['09:00'],
    },
  },
  data() {
    return {
      availableTimes: TIME_INTERVALS_30_MINUTES,
      showDropdown: false,
    };
  },
  methods: {
    toggleDropdown() {
      event.preventDefault();
      event.stopPropagation();
      this.showDropdown = !this.showDropdown;

      if (this.showDropdown) {
        document.addEventListener("click", this.handleClickOutside);
        this.$nextTick(() => {
          if (this.$refs.dropdownList) {
            const targetIndex = this.availableTimes.indexOf('09:00');
            if (targetIndex !== -1) {
              const listItemHeight = this.$refs.dropdownList.querySelector('li').offsetHeight;
              this.$refs.dropdownList.scrollTop = targetIndex * listItemHeight;
            }
          }
        });
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },

    handleClickOutside(event) {
      if (this.$refs.dropdownList && !this.$refs.dropdownList.contains(event.target)) {
        this.showDropdown = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },

    selectTime(time) {
      if (!this.selectedTimes.includes(time) && this.selectedTimes.length < 16) {
        this.selectedTimes.push(time);
        this.selectedTimes.sort((a, b) => this.compareTimes(a, b));
        this.$emit('updateTime', this.selectedTimes);
      }
      this.showDropdown = false;
    },

    compareTimes(a, b) {
      const [aHours, aMinutes] = a.split(":").map(Number);
      const [bHours, bMinutes] = b.split(":").map(Number);
      return aHours * 60 + aMinutes - (bHours * 60 + bMinutes);
    },

    removeTime(index) {
      if (this.selectedTimes.length > 1) {
        this.selectedTimes.splice(index, 1);
        this.$emit('updateTime', this.selectedTimes);
      }
    },

    isTimeSelected(time) {
      return this.selectedTimes.includes(time);
    },

    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/less/variables.less";

.time-chips {
  display: flex;
  align-items: center;
  max-width: 340px;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px 0 5px 5px;
  position: relative;
}

.chip {
  display: flex;
  justify-content: space-between;
  width: 70px;
  height: 30px;
  align-items: center;
  background-color: #f3f1f1;
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #777;

  img {
    width: 20px;
  }
}

.remove-chip {
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: rgb(114, 114, 114);
  font-size: 14px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.container-add {
  position: relative;
}

.add-chip {
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.time-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 182px;
  overflow-y: auto;
  width: 130px;
  bottom: 90%;
  left: 100%;

  &::-webkit-scrollbar {
    border-radius: @br;
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: @br;
    width: 8px;
    background-color: fade(#000000, 24%);

    &:hover {
      background-color: fade(#000000, 30%);
    }
  }

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & li {
    padding: 5px 10px;
    cursor: pointer;
  }

  & li.disabled {
    color: #aaa;
    pointer-events: none;
  }

  & li.disabled:hover {
    background-color: inherit;
  }

  & li:hover {
    background-color: #f0f0f0;
  }
}
</style>
