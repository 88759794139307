<template>
    <button
            :class="['btn btn-middle', {disabled: !authorities.includes('ROLE_SUBSCRIBED_USER')}]"
            type="button"
            @click="clickBtn"
            :title="!authorities.includes('ROLE_SUBSCRIBED_USER') ? 'Создание фильтров доступно только при наличии платной подписки' : ''"
    >
        <img src="../../assets/img/ico-filter-plus-white.svg" alt="">
        <span>Создать фильтр</span>
    </button>
</template>

<script>
    import {mapGetters} from "vuex"

    export default {
        name: "PurchasesTableCustomFilterBtn",
        computed: {
            ...mapGetters('auth', ['authorities']),
        },
        methods: {
            clickBtn() {
                this.$emit('clickBtn')
            },

        }
    }
</script>

<style lang="less" scoped>
    button {
        padding: 0 20px;

        img {
            display: block;
            margin-right: 8px;
        }
    }
</style>
