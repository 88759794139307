<template>
  <span ref="wrapper" class="proposal-source-wrapper">
      <p v-if="!showTooltip"
         ref="proposalSource">
    {{ linkText }}
  </p>
      <el-popover v-else
              popper-class="proposal-source-tooltip"
              placement="left"
              trigger="hover">
    <p>{{ linkText }}</p>

    <template slot="reference">
      <p ref="proposalSource">{{linkText}}</p>
    </template>
  </el-popover>
  </span>

</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "ProposalSourceCellRendererFramework",
  data() {
    return {
      showTooltip: false,
      resizeObserver: null
    }
  },
  mounted() {
    setTimeout(() => {
      this.setValueForTooltip()

      if (this.$refs?.wrapper) {
        this.resizeObserver = new ResizeObserver(() => {
          this.setValueForTooltip()
        })
        this.resizeObserver.observe(this.$refs.wrapper)
      }
    }, 1000)
  },
  computed: {
    linkText() {
      const url = new URL(this.params.value)
      return url.host
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.wrapper)
      this.resizeObserver = null
    }
  },
  methods: {
    setValueForTooltip() {
      const el = this.$refs.proposalSource
      const elParent = this.$refs.wrapper

      if(el && elParent) {

        const paddingLrEl = (parseInt(window.getComputedStyle(elParent).paddingLeft) +
            parseInt(getComputedStyle(elParent).paddingRight))

        this.showTooltip = (elParent.clientHeight < el.offsetHeight) ||
            ((elParent.clientWidth - paddingLrEl) < el.offsetWidth) ||
            (el.scrollHeight > el.clientHeight) ||
            (el.scrollWidth > el.clientWidth) || this.isBeloil
      }
    }
  }
})
</script>

<style lang="less">
.proposal-source-wrapper {
  display: block;
}
.proposal-source-tooltip {
  padding: 4px 8px;
  font-family: Roboto, sans-serif;
  color: #F7F7F7;
  letter-spacing: 0.3px;
  background-color: rgba(121, 145, 156, 0.9);
  line-height: 16px;
  word-break: break-word;
  border: none;
  max-width: 290px;
  width: max-content !important;
  min-width: 40px!important;

  .popper__arrow {
    display: none;
  }

}

p {
  display: inline;
}
</style>
